const cache = {};
export const fetchArticle = async (name) => {
    var _a;
    if (name in cache)
        return cache[name];
    const { query } = await fetch(`https://en.wikipedia.org/w/api.php?action=query&prop=extracts&format=json&exintro=&titles=${encodeURIComponent(name)}&origin=*`).then((res) => res.json());
    const data = (_a = Object.entries(query.pages).find(([k, v]) => { var _a; return v && k === ((_a = v.pageid) === null || _a === void 0 ? void 0 : _a.toString()); })) === null || _a === void 0 ? void 0 : _a[1];
    if (data.extract) {
        const node = document.createElement('div');
        node.innerHTML = data.extract;
        node
            .querySelectorAll('[title~=pronunciation]')
            .forEach((node) => node.remove());
        const removeEmpty = (parent) => {
            Array.from(parent.children).forEach((node) => {
                removeEmpty(node);
                if (/^\s*$/.test(node.innerHTML))
                    return node.remove();
            });
        };
        removeEmpty(node);
        data.extract = node.innerHTML.replace(/\(\s*\)/g, '');
    }
    return (cache[name] = data);
};
export const read = (name) => cache[name];
